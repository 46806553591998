@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");

/* font-family: "Lexend Deca", sans-serif; */

:root {
  --Lexend-font: "Lexend Deca", sans-serif;

  --Blue-Dianne: #244856;
  --White-Color: #fff;
  --black-Color: #000;
  --alto-Color: #d9d9d9;
  --alto2: #d5d5d5;
  --Dusty-Gray: #9f9d9d;
  --Catskill-White: #f1f5f9;
  --Casal-Color: #2d5a6c;

  /* BOX SHADOW */
  --Shadow-1: 0 3px 10px #ccc;
  --Shadow-2: 0 -1px 10px #ccc;
}

body,
label,
h1,
h2,
h3,
h4,
h5 p,
a {
  font-family: "Lexend Deca", sans-serif;
}

ul,
li {
  list-style: none;
  font-family: "Lexend Deca", sans-serif;
}

/* =============== LEFT PANNEL OR MENU START =============== */

.leftPanel {
  position: fixed;
  z-index: 99;
  top: 0px;
  left: 0px;
  width: 75px;
  height: 100%;
  padding: 20px 0;
  background: #7233a3;
  transition: all 0.2s;
  font-family: "Lexend Deca", sans-serif;
}
.leftPanel .logo {
  text-align: center;
  height: 80px;
}

.leftPanel .logo img.lgLogo {
  width: 0px;
  height: 48px;
  transition: all 0.2s;
  padding: 0;
}
.leftPanel .logo img.iconLogo {
  max-width: 100%;
  padding: 0px;
  transition: all 0.2s;
}
.leftPanel .logo span {
  background: #fff;
  height: 50px;
  width: 50px;
  line-height: 42px;
  padding: 5px;
  border-radius: 100%;
  display: inline-block;
  transition: all 0.2s;
}

.leftPanel:hover .logo img.lgLogo,
.leftPanel.active .logo img.lgLogo {
  width: 250px;
  height: auto;
}

.leftPanel:hover .logo img.iconLogo,
.leftPanel.active .logo img.iconLogo {
  max-width: 0px;
  height: 0px;
}

.leftPanel:hover .logo span,
.leftPanel.active .logo span {
  height: 0px;
  width: 0px;
  line-height: 0px;
  padding: 0px;
  border-radius: 100%;
  display: inline-block;
}

.taskMenu a {
  color: #fff;
  font-size: 24px;
  padding: 18px 0;
  text-decoration: none;
  display: block;
  transition: all 0.2s;
}
.taskMenu a span {
  font-size: 0px;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.2s;
}
.taskMenu ul {
  margin: 0px;
  padding: 0;
}
.taskMenu ul li {
  margin: 0px;
  padding: 0 20px;
  transition: all 0.2s;
}
.taskMenu > ul > li:hover {
  background: var(--Catskill-White);
}
.taskMenu > ul > li:hover a {
  color: var(--black-Color);
}

.leftPanel:hover,
.leftPanel.active {
  width: 300px;
  transition: all 0.2s;
}

.leftPanel:hover .taskMenu ul li,
.leftPanel.active .taskMenu ul li {
  text-align: left;
}

.leftPanel:hover .taskMenu a span,
.leftPanel.active .taskMenu a span {
  font-size: 16px;
  opacity: 1;
}

/* ======== SUBMENU START ======== */

.subMemuOut {
  position: absolute;
  top: 0;
  left: 100%;
  padding: 0px;
  min-width: 0px;
  min-height: 100vh;
  background-color: var(--Catskill-White);
  /* transition:all 0.2s; */
  /* background-color:#7233a3; */
}
.subMemuOut li a {
  font-size: 0px;
}

.taskMenu .subMemuOut li {
  padding: 0px;
}
.taskMenu .subMemuOut h3 {
  font-size: 0px;
}
.leftPanel .taskMenu li:hover .subMemuOut h3 {
  color: #fff;
  font-size: 18px;
}
.leftPanel .taskMenu li:hover .subMemuOut {
  min-width: 250px;
  padding: 40px 40px 25px;
}
.leftPanel .taskMenu li:hover .subMemuOut ul {
  margin-left: 15px;
  margin-bottom: 25px;
}
.leftPanel .taskMenu li:hover .subMemuOut li a {
  font-size: 16px;
  padding: 15px 0;
}

/* ======== SUBMENU END ======== */

/* =============== LEFT PANNEL OR MENU END =============== */

.dashBoxMain {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-left: 75px;
}

.taskBodyColor {
  background: #f2f2f2;
}

.taskHeader .gareSet a,
.taskHeader .alertNote a {
  border: 1px solid var(--alto-Color);
  color: var(--Dusty-Gray);
  display: block;
  height: 40px;
  width: 40px;
  line-height: 37px;
  font-size: 25px;
  text-align: center;
  border-radius: 100%;
}

.taskHeader {
  background: var(--White-Color);
  box-shadow: 0 0 5px #ccc;
  padding: 10px;
  z-index: 5;
}

.userName a {
  color: #000;
}
.userName .btn.dropdown-toggle,
.userName .btn.dropdown-toggle:focus,
.userName .btn.dropdown-toggle:active,
.userName .btn.dropdown-toggle:focus-visible,
.userName .btn.dropdown-toggle:hover {
  background-color: transparent;
  border: 0;
  color: #000;
  outline: none;
  box-shadow: none;
  background: none;
}

.pageTitle1 {
  background: var(--White-Color);
}
.pageTitle1 h3 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
}
.pageTitle1 p {
  font-size: 14px;
  color: #000;
}

.pageContent {
  background: var(--White-Color);
  border: 1px solid var(--alto2);
  border-radius: 5px;
  font-family: var(--Lexend-font);
}
.lineTitle {
  font-size: 20px;
  color: var(--Blue-Dianne);
  font-family: var(--Lexend-font);
  font-weight: 600;
  border-bottom: 1px solid var(--alto2);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.cardBox3 {
  grid-template-columns: repeat(auto-fill, 320px);
  display: grid;
  gap: 30px;
}
.cardBox3 .card {
  border: 0px;
  box-shadow: none;
  border-radius: 15px;
}
.cardBox3 .card p {
  font-size: 14px;
  color: #434343;
}
.cardBox3 .card h5 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.cardBox3 .toolList {
  display: flex;
  margin: 0px;
  padding: 0px;
}
.cardBox3 .toolList li {
  height: 54px;
  width: 54px;
  background: #7233a3;
  border-radius: 100%;
  text-align: center;
  line-height: 54px;
}
.cardBox3 .toolList li + li {
  margin-left: -8px;
  box-shadow: -2px 0px 3px -1px #4e4e4e;
}
.cardBox3 .toolList li:first-child {
  background: #e73737;
}
.cardBox3 .toolList li:nth-child(2) {
  background: #3f4eee;
}
.cardBox3 .toolList li:nth-child(3) {
  background: #10aa65;
}
.cardBox3 .toolList li:nth-child(4) {
  background: #10aa65;
}
.cardBox3 .toolList li:nth-child(5) {
  background: #075aba;
}

.cardBox3 .toolList li img {
  max-width: 40px;
  max-height: 40px;
}

.divCenterMidel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  padding: 20px;
}

.recordIng h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000;
}
.recordIng h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.recordIng p {
  color: #000;
}

.recordIng .recordIcon,
.recordIng .recordStop {
  height: 175px;
  width: 175px;
  border-radius: 100%;
  text-align: center;
  line-height: 175px;
  box-shadow: 0px 4px 30px 0px #0000004d;
  cursor: pointer;
}
.recordIng .recordIcon {
  border: 2px solid #7233a3;
}
.recordIng .recordStop {
  border: 2px solid #889d02;
}

.recordIng .recordIcon.secondOption {
  height: 150px;
  width: 150px;
  line-height: 150px;
}
.recordIng .recordIcon.secondOption img {
  width: 120px;
}

.scriptBox {
  height: calc(100vh - 350px);
  overflow: auto;
}
.blankBtn {
  min-width: 200px;
  border: 1px solid #000;
  font-size: 18px;
  font-weight: 600;
  background: none;
}
.blankBtn:hover {
  border: 1px solid #000;
  /* background: ; */
}
.blueBtn {
  min-width: 200px;
  border: 1px solid #7233a3;
  font-size: 18px;
  font-weight: 600;
  background: #7233a3;
  color: #fff;
}
.blueBtn:hover {
  background: #7233a3;
  border: 1px solid #7233a3;
  color: #fff;
}

.blankBtn2 {
  min-width: 200px;
  border: 2px solid #7233a3;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  background: none;
}
.blankBtn2 > span {
  font-size: 30px;
  line-height: 25px;
}
.blankBtn2:hover {
  border: 2px solid #7233a3;
  color: #000;
  opacity: 0.7;
}

.btn.min-w-auto {
  min-width: auto;
}

.slideRight {
  position: fixed;
  right: -450px;
  top: 0px;
  height: 100%;
  overflow: auto;
  background: #fff;
  width: 0px;
  z-index: 11;
  box-shadow: 0px 4px 10px 0px #00000040;
  transition: all, 0.5s;
}
.slideRight.active {
  width: 450px;
  right: 0px;
  border-radius: 0px;
}
.slideRight .topStrip {
  background: #e73737;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
.slideRight .topStrip.podcast {
  background: #e737ac;
}
.slideRight h4 {
  font-size: 18px;
}
.slideRight.saveRecord .topStrip {
  background: #3f4eee;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}

.slideRight .bottomBox {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  background: #fff;
}

.slideRight.spreadSheet .topStrip {
  background: #10aa65;
}

.gmailBtn {
  font-size: 20px;
  font-weight: 600;
  background: none;
  border: 1px solid #b0abab;
  box-shadow: 0px 9px 12px 0px #00000026;
}

.runOnce {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3px 20px;
  font-size: 15px;
  font-weight: 600;
}
.runOnce > span {
  color: #7233a3;
  font-size: 40px;
  display: inline-block;
  line-height: 28px;
  margin-right: 10px;
}

.aiIcon ul {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.aiIcon ul li {
  padding: 5px;
  border-radius: 100%;
  text-align: center;
}
.aiIcon ul li.active {
  position: relative;
  background: none;
}
/* .aiIcon ul li.active{border:2px dashed #000; background:none; border-radius:100%;} */
.aiIcon ul li a {
  display: block;
  height: 180px;
  width: 180px;
  position: relative;
  z-index: 2;
  border-radius: 100%;
  background: #3f4eee;
  text-align: center;
  line-height: 180px;
}
.aiIcon ul li:first-child a {
  background: #e73737;
}
.aiIcon ul li:nth-child(2) a {
  background: #3f4eee;
}
.aiIcon ul li:nth-child(3) a {
  background: #10aa65;
}
.aiIcon ul li:nth-child(4) a {
  background: #075aba;
}
.aiIcon ul li.podcast a {
  background: #e737ac;
}

.aiIcon ul li.active a::after {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  height: 192px;
  width: 192px;
  border-radius: 100%;
  border: 2px dashed #e73737;
  background: none;
  box-sizing: border-box;
  animation: rotation 8s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.aiIcon ul li.active:first-child::after {
  border-color: #e73737;
}
.aiIcon ul li.active:nth-child(2)::after {
  border-color: #3f4eee;
}

.aiIcon ul li.active:first-child a::after {
  border-color: #e73737;
}
.aiIcon ul li.active:nth-child(2) a::after {
  border-color: #3f4eee;
}

.aiIcon ul li.podcast.active a::after {
  border-color: #e737ac;
}

.aiIcon ul li img {
  max-width: 80px;
  max-height: 80px;
}
.aiIcon h3 {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  margin: 15px 0 0;
}
.aiIcon p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.cstRadio .form-check-input {
  height: 20px;
  width: 20px;
}
.cstRadio label.form-check-label {
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
}

.formGroup label {
  font-size: 16px;
  font-weight: 500;
}
.formGroup .form-control,
.formGroup .form-select {
  background-color: #f5f6f8;
  border: 1px solid #d8d8d8;
  height: 45px;
  border-radius: 0;
  font-weight: 400;
  font-family: "Lexend Deca", sans-serif;
}
.formGroup .cstDropdown .dropdown-toggle {
  background: #f5f6f8;
  color: #000;
  border-radius: 0;
  border: 1px solid #e2e3e4;
  width: 100%;
  height: 45px;
  text-align: left;
  display: flex;
  align-items: center;
}
.formGroup .cstDropdown .dropdown-toggle::after {
  margin-left: auto;
}
.formGroup .cstDropdown .dropdown-menu {
  padding: 0px;
  min-width: 250px;
}
.formGroup .cstDropdown .dropdown-menu a {
  line-height: 35px;
  color: #595959;
}
.formGroup .cstDropdown .dropdown-menu a + a {
  border-top: 1px solid #e2e2e2;
}

.alertModal .modal-content {
  padding: 0px;
}
.alertModal .modalIcon {
  font-size: 150px;
  text-align: center;
  line-height: 150px;
  color: #a5dc86;
}
.alertModal h3 {
  font-size: 30px;
  font-weight: 600;
}
.alertModal .fullBtn {
  background: #ddd;
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
}
